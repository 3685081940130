<template>
  <div>
    <b-modal
        :id="'modal-custom-domain-info-group-' + group.group_id"
        :ref="'modal-custom-domain-info-group-' + group.group_id"
        footer-class="flex-nowrap"
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.addCustomDomain.title') }}</h3>
      </template>

      <template #default>
        <div class="mt-4 mx-5 mb-4">
          <modal-custom-domain-instructions :slug="group.slug"></modal-custom-domain-instructions>
        </div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="close()"
        >
          {{ $t('buttons.close') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ModalCustomDomainInstructions from '@/components/modals/ModalCustomDomainInstructions.vue';

export default {
  name: 'ModalCustomDomainInfo',
  components: { ModalCustomDomainInstructions },
  props: ['group'],
};
</script>

<style scoped>

</style>
