var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          staticClass: "green-button-transparent border-0 ml-3",
          attrs: {
            disabled:
              !_vm.isSysAdmin &&
              !_vm.$hasPrivileges(
                _vm.$privileges("CAN_ADD_SUBGROUP_TO_GROUP"),
                _vm.isSysAdmin,
                _vm.userPrivileges
              )
          },
          on: {
            click: function($event) {
              return _vm.$bvModal.show("modal-add-group" + _vm.tableIndex)
            }
          }
        },
        [
          _c("b-icon", {
            staticClass: "mr-2 top--3",
            attrs: {
              icon: "plus-circle-fill",
              "aria-hidden": "true",
              scale: "1"
            }
          }),
          _vm._v(" " + _vm._s(_vm.$t("buttons.addGroup")) + " ")
        ],
        1
      ),
      _c("b-modal", {
        ref: "modal-add-group" + _vm.tableIndex,
        attrs: {
          id: "modal-add-group" + _vm.tableIndex,
          "footer-class": "flex-nowrap",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [_vm._v(_vm._s(_vm.$t("modals.createGroup.title")))])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "mt-4 mx-5 mb-4" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        placeholder: _vm.$t("modals.createGroup.name"),
                        autofocus: "",
                        state: _vm.groupDuplicateError,
                        debounce: "500"
                      },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.createGroup.apply(null, arguments)
                        },
                        input: _vm.makeFieldDirty
                      },
                      model: {
                        value: _vm.newGroupName,
                        callback: function($$v) {
                          _vm.newGroupName = $$v
                        },
                        expression: "newGroupName"
                      }
                    }),
                    _c("b-form-invalid-feedback", [
                      _vm._v(" " + _vm._s(_vm.groupCreateError) + " ")
                    ]),
                    _vm.groupSlugExists
                      ? _c("div", { staticClass: "invalid" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "groupManagement.duplicateNameWithParam",
                                  { groupName: _vm.newGroupName }
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _c("div", { staticClass: "preview text-center mb-3" }, [
                  _c("small", [_vm._v(_vm._s(_vm.computedUrl))])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: { click: _vm.closeModal }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: {
                      disabled: _vm.isButtonDisabled,
                      variant: "primary",
                      block: ""
                    },
                    on: { click: _vm.createGroup }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("modals.createGroup.validate")) + " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }