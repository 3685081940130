var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-modal", {
        ref: "modal-custom-domain-info-group-" + _vm.group.group_id,
        attrs: {
          id: "modal-custom-domain-info-group-" + _vm.group.group_id,
          "footer-class": "flex-nowrap",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("modals.addCustomDomain.title")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "mt-4 mx-5 mb-4" },
                  [
                    _c("modal-custom-domain-instructions", {
                      attrs: { slug: _vm.group.slug }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.close")) + " ")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }